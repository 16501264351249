import React from "react"

import NavBar from "../component/NavBar"

import "./About.css"

class About extends React.Component {

  render() {
    return(
      <div className="Page">
        <NavBar showLogo={true} />
        <div id="About" className="Content Veiling">
          <div id="AboutCard">
            <div id="AboutCardLeft"></div>
            <div id="AboutCardRight">
              <p id="AboutCardTitle">Magunkról</p>
              <p id="AboutCardText">
                Célunk az éberség elérése és fenntartása. A csoport tagjainak többsége keresztény
                gyökerekkel rendelkezik, a gyakorlás azonban vallásfüggetlen. Szeretettel várunk bármilyen
                vallású, gyakorló társakat.
                <br/><br/>
                A csoport vezetői, Marghescu Marika és Subosits Tamás, Willigis Jäger bencés szerzetes, Zen
                mester tanítványai.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About
